import { supportedLanguageCodes, SupportedLanguageCode, YuLifeLocale } from "@hooks";
import { StaticRoutes, staticRoutesByLocale } from "@routes";
import dynamic from "next/dynamic";
import { LoadingSpinner } from "components/molecules/LoadingSpinner";

export type ComponentMap = Partial<Record<YuLifeLocale | "default", string>>;

/**
 * Returns a dynamically loaded component based on the provided locale.
 * @param locale The user’s locale.
 * @param componentMap A map of locale to component paths.
 * @returns The dynamically imported React component.
 */
export function getDynamicComponent<Props>(
  locale: YuLifeLocale,
  componentMap: ComponentMap,
): React.ComponentType<Props> {
  const componentPath = componentMap[locale] ?? componentMap["default"];

  if (!componentPath) {
    throw new Error(`No component found for locale: ${locale}`);
  }

  return dynamic<Props>(() => import(componentPath), {
    loading: () => <LoadingSpinner />,
    ssr: false, // Set to false if server-side rendering is not supported for the component
  });
}

export const languageOptions = {
  uk: { country: "United Kingdom", flag: "GB-UKM", rewardLocation: "GB" },
  us: { country: "United States", flag: "US", rewardLocation: "US" },
  za: { country: "South Africa", flag: "ZA", rewardLocation: "ZA" },
  jp: { country: "Japan", flag: "JP", rewardLocation: "JP" },
};

const formatLanguageCode = (language: string = "en-GB"): string => {
  const [first, second = ""] = language.split("-");

  if (second) {
    return `${first.toLowerCase()}-${second.toUpperCase()}`;
  }

  return first.toLowerCase();
};

const convertLanguageToNextLocale = (language?: string): YuLifeLocale => {
  switch (language) {
    case "en-US":
      return "us";
    case "en-ZA":
      return "za";
    case "en-GB":
    default:
      return "uk";
  }
};

export const getNavigatorLocale = () => {
  const language = [
    ...(navigator.languages || []),
    navigator.userLanguage,
    navigator.language,
    navigator.browserLanguage,
  ].find((language) =>
    supportedLanguageCodes.includes(formatLanguageCode(language) as SupportedLanguageCode),
  );

  return convertLanguageToNextLocale(language);
};

export const getLocaleName = (locale: string = "uk"): string => {
  const country = locale.slice(-2).toLowerCase();
  const language = languageOptions[country === "gb" ? "uk" : country];

  if (!language) {
    const regionNamesInEnglish = new Intl.DisplayNames(["en"], { type: "region" });
    return regionNamesInEnglish.of(country.toUpperCase());
  }

  return language.country || "";
};

export const isLocaleInStaticRoute = (locale: YuLifeLocale, route: StaticRoutes): boolean => {
  const locales = staticRoutesByLocale[route];
  return !!locales?.length && locales.includes(locale);
};

export const convertLocaleToRewardsRegion = (locale: YuLifeLocale) => {
  switch (locale) {
    case "us":
      return "US";
    case "za":
      return "ZA";
    case "uk":
    default:
      return "GB";
  }
};

export const generateAbsoluteUrl = (locale: YuLifeLocale, path: string): string => {
  return `${process.env.BASE_URL}${locale === "uk" ? "" : `/${locale}/`}/${path}`;
};
